<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="header-title" slot="header">
      <p>{{ $t('please enter your facebook emailid for login') }}</p>
    </div>
    <div class="component-container" slot="component">
      <form @submit.prevent="facebookLogin">
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
          <div class="form-control">
            <label>{{ $t('email id') }}</label>
            <br />
            <input
              id="facebookemail"
              type="email"
              ref="email"
              v-model="userEmail"
              :placeholder="`${emailidPlaceholder}`"
            />
          </div>
        </div>
        <div class="login-button">
          <button
            class="button-primary login"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            type="submit"
          ><span class="btn-txt-color">{{ $t('login') }}</span></button>
        </div>
      </form>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";

export default {
  props: ["closePopup", "payload"],
  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userEmail: "",
      emailidPlaceholder: "",
      formError: null,
      providerUniqueId: _providerId
    };
  },
  watch: {
    formError() {
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    }
  },
  mounted() {
    this.emailidPlaceholder = this.$t('email id');

    eventBus.$on("facebookemail-response", response => {
      if (response.reason) {
        this.formError = response.reason;
      } else {
        console.log("facebookemail-response", response);
      }
    });
  },
  methods: {
    facebookLogin() {
      if (!this.validateEmail(this.userEmail)) return;
      let payload = {
        app: this.payload.app,
        type: this.payload.type,
        emailRequired: true,
        params: {
          devicetype: this.payload.params.devicetype,
          fbtoken: this.payload.params.fbtoken,
          email: this.userEmail
        }
      };
      eventBus.$emit("subscriberLogin", payload);
    }
  },
  components: {
    Popup: () =>
      import(
        /* webpackChunkName: "facebookEmail" */ "@/components/Slots/Popup.vue"
      )
  },
  mixins: [Utility]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.header-title {
  padding: 4rem 3rem 2rem;
  p {
    color: #c1c1c1;
    font-family: $font-regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
  }
}
.component-container {
  padding: 0 3rem 3rem;
}
</style>
