<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="sharedContent" slot="component">
      <div class="sharedContent-image-placeholder loading" v-if="!content"></div>
      <div class="sharedContent-image-container" v-else>
        <div
          class="sharedContent-image-container-card"
          :style="[{ 'background-image': `url(${getPoster()})` }]"
        ></div>
      </div>
      <div class="sharedContent-info">
        <div v-if="!content">
          <p class="sharedContent-info-title-placeholder loading"></p>
          <p class="sharedContent-info-description-placeholder loading"></p>
          <p class="sharedContent-info-description-placeholder loading"></p>
        </div>
        <div v-else>
          <h2 class="sharedContent-info-title">{{content.title}}</h2>
          <div class="sharedContent-info-contain">
            <div class="sharedContent-info-meta">
              <p class="sharedContent-info-meta-item">{{content.category}}</p>
              <p class="sharedContent-info-meta-item">{{content.genre}}</p>
              <p class="sharedContent-info-meta-item">{{content.contentlanguage[0]}}</p>
            </div>
            <div class="sharedContent-info-btns">
              <div class="activity-btns">
                <LikeBtn :content="content" :width="screen.width < 576 ? '15px' : '25px'" :height="screen.width < 576 ? '15px' : '25px'" class="hover-change"></LikeBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>Like</span>
                </div>
              </div>
              <div class="activity-btns">
                <DislikeBtn
                  :content="content"
                  :width="screen.width < 576 ? '15px' : '25px'"
                  :height="screen.width < 576 ? '15px' : '25px'"
                  class="hover-change"
                ></DislikeBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>Dislike</span>
                </div>
                <!-- <p class="activity-counts">{{dislikeCountDisplay}}</p> -->
              </div>
              <div class="activity-btns">
                <WatchListBtn
                  :content="content"
                  :width="screen.width < 576 ? '15px' : '25px'"
                  :height="screen.width < 576 ? '15px' : '25px'"
                  class="hover-change"
                ></WatchListBtn>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>Watchlist</span>
                </div>
              </div>
              <div class="activity-btns">
                <detailShare
                  :content="content"
                  :width="screen.width < 576 ? '15px' : '25px'"
                  :height="screen.width < 576 ? '15px' : '25px'"
                  class="hover-change"
                ></detailShare>
                <div class="triangle"></div>
                <div class="tooltip">
                  <span>Share</span>
                </div>
              </div>
            </div>
          </div>
          <p class="sharedContent-info-description">{{content.longdescription}}</p>
          <button
            class="button-primary sharedContent-info-btn"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            v-if="isButtonEnable"
            @click="buttonHandler"
          ><span class="btn-txt-color">{{ buttonStatus }}</span></button>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";

export default {
  props: {
    objectId: {
      type: String
    },
    closePopup: {
      type: Function
    }
  },
  data() {
    return {
      width: "45%",
      margin: "8% auto",
      radiusType: "full",
      content: null,
      isButtonEnable: false,
      buttonStatus: null,
      subscriptions: [],
      purchases: [],
      filteredAvailabilities: [],
      providerUniqueId: _providerId
    };
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList"
    ])
  },
  watch: {
    subscriberid(val) {
      if (val) {
        this.fetchButtonStatus();
      }
    },
    availabilityList(val) {
      if (val) {
        this.fetchButtonStatus();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
      //fetch detail card button status buy or watchnow.
      this.fetchButtonStatus();
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
      this.fetchButtonStatus();
    }
  },
  created() {
    let payload = { contentid: this.objectId };
    eventBus.$emit("getContentDetail", payload);
    console.log("shared content branch", this.content);
  },
  mounted() {
    eventBus.$on(`contentDetail-response-${this.objectId}`, response => {
      console.log("content detail response", response.data);
      if (!response.reason) {
        this.content = response.data;
      }
    });
  },
  methods: {
    getPoster() {
      let content = this.content;
      if (content.poster && content.poster.length) {
        let index = content.poster.findIndex(element => {
          return (
            (element.quality === "HD" || element.quality === "SD") &&
            element.postertype === "LANDSCAPE"
          );
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      }
    },
    fetchButtonStatus() {
      console.log("availabilities", this.availabilityList);
      console.log("subscriptionlist", this.subscriptions);
      console.log("purchaselist", this.purchases);

      let content = this.content;
      let pricemodel = [];
      //checking if content object has contentdetails.

      if (
        content.hasOwnProperty("contentdetails") &&
        content.contentdetails.length > 0
      ) {
        //here picking the suitable contentdetail object login is pending.
        /** need to write logic for the above*/

        //Initially adding the button status as buy for the content.
        this.isButtonEnable = true;

        //filtering availabilities from availabitlity list.
        this.filteredAvailabilities = [];

        //here fetching content details of first index later this has to be dynamic.
        let content_details = content.contentdetails[0];

        let isContentPlayable = false;

        //filtering avaliabilitylist based on content availability set.
        content_details.availabilityset.forEach(availability => {
          let index = this.availabilityList.findIndex(element => {
            return element.availabilityid === availability;
          });
          if (index > -1) {
            pricemodel.push(this.availabilityList[index].pricemodel);
            this.filteredAvailabilities.push(this.availabilityList[index]);
          }
        });

        console.log("filtered", this.filteredAvailabilities);

        //based on pricemodel of availabilities assigning buttonstatus.
        if (
          (pricemodel.includes("PLAN") && pricemodel.includes("PAID")) ||
          pricemodel.includes("RENTAL")
        ) {
          this.buttonStatus = "SUBSCRIBE OR PURCHASE";
        } else if (pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if (pricemodel.includes("FREE")) {
          this.buttonStatus = "LOGIN & WATCH";
        } else {
          this.buttonStatus = "PURCHASE & WATCH";
        }

        this.filteredAvailabilities.forEach(element => {
          //check avalabilty belongs to subscribed list.
          if (element.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.forEach(subscription => {
                let value = subscription.availabilityset.includes(
                  element.availabilityid
                );
                if (value && !isContentPlayable) {
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY NOW";
                  this.content.isDrmContent = true;
                  this.content.videoType = "Content";
                  this.content.playback_details = {
                    packageid: content_details.packageid,
                    availabilityid: element.availabilityid
                  };
                  isContentPlayable = true;
                }
              });
            }
          }
          //check availability belongs to purchased list.
          else if (
            element.pricemodel === "PAID" ||
            element.pricemodel === "RENTAL"
          ) {
            if (this.subscriberid && this.purchases.length > 0) {
              //write purchase check logic.
              this.purchases.forEach(purchase => {
                if (
                  purchase.availabilityid === element.availabilityid &&
                  this.content.objectid === purchase.objectid
                ) {
                  if (!isContentPlayable) {
                    this.isButtonEnable = true;
                    this.buttonStatus = "PLAY NOW";
                    this.content.videoType = "Content";
                    this.content.isDrmContent = true;
                    this.content.playback_details = {
                      packageid: content_details.packageid,
                      availabilityid: element.availabilityid
                    };
                    isContentPlayable = true;
                  }
                }
              });
            }
          }
          //check availability belongs to free or adbased pricemodel.
          else if (
            (element.pricemodel === "FREE" ||
              element.pricemodel === "ADBASED") &&
            this.subscriberid
          ) {
            if (!isContentPlayable) {
              this.isButtonEnable = true;
              this.buttonStatus = "PLAY NOW";
              this.content.videoType = "Content";
              this.content.isDrmContent = true;
              //creating this playback_details for content playback.
              this.content.playback_details = {
                packageid: content_details.packageid,
                availabilityid: element.availabilityid
              };
            }
          }
        });
      } else if (
        content.hasOwnProperty("trailer") &&
        content.trailer.length > 0
      ) {
        this.isButtonEnable = true;
        if (this.subscriberid) {
          this.content.isDrmContent = false;
          this.buttonStatus = "WATCH TRAILER";
          this.content.videoType = "Trailer";
        } else {
          this.buttonStatus = "LOGIN & WATCH";
        }
      } else {
        this.isButtonEnable = true;
        this.buttonStatus = "COMING SOON";
      }
    },
    buttonHandler() {
      if (!this.subscriberid && this.buttonStatus === "PLAY NOW") {
        this.togglePlayer(true);
      } else if (
        !this.subscriberid &&
        (this.buttonStatus === "SUBSCRIBE OR PURCHASE" ||
          this.buttonStatus === "SUBSCRIBE & WATCH" ||
          this.buttonStatus === "PURCHASE & WATCH" ||
          this.buttonStatus === "LOGIN & WATCH")
      ) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup"
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        (this.buttonStatus === "SUBSCRIBE OR PURCHASE" ||
          this.buttonStatus === "SUBSCRIBE & WATCH" ||
          this.buttonStatus === "PURCHASE & WATCH")
      ) {
        // let plans = this.filteredAvailabilities.filter(el => el.pricemodel === 'PLAN')
        let plans = this.filteredAvailabilities;
        let payload = {
          listPlans: "Availabilities",
          availabilities: this.filteredAvailabilities,
          content: this.content
        };
        eventBus.$emit("subscribePopup", payload);
      } else if (
        (this.subscriberid && this.buttonStatus === "PLAY NOW") ||
        this.buttonStatus === "WATCH TRAILER"
      ) {
        this.togglePlayer(true);
      }
    },
    togglePlayer(state) {
      let payload = {
        content: this.content,
        state: state
      };
      eventBus.$emit("togglePlayer", payload);
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    LikeBtn: () =>
      import(
        /* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"
      ),
    DislikeBtn: () =>
      import(
        /* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"
      ),
    WatchListBtn: () =>
      import(
        /* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"
      ),
    detailShare: () =>
      import(
        /* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"
      )
  },
  beforeDestroy() {
    eventBus.$off("contentDetail-response");
  }
};
</script>

<style lang='scss' scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.sharedContent {
  &-image-placeholder {
    height: 400px;
    background-color: $clr-dark-gd2;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    overflow: hidden;
  }
  &-image-container {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    height: 400px;
    width: 100%;
    &-card {
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
  &-info {
    background-color: $clr-dark-gd2;
    padding: 20px;
    &-title-placeholder {
      background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      height: 15px;
      width: 25%;
      margin: 15px 0;
      overflow: hidden;
    }
    &-description-placeholder {
      background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      height: 15px;
      margin: 15px 0;
      overflow: hidden;
    }
    &-title {
      color: #ffffff;
      font-family: $font-regular;
      font-weight: bold;
      margin: 15px 0;
    }
    &-contain {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &-meta {
      display: flex;
      &-item {
        color: #ffffff;
        font-family: $font-regular;
        font-weight: bold;
        margin-right: 10px;
        text-transform: uppercase;
      }
    }
    &-btns {
      display: flex;
      justify-content: flex-end;
      .activity-btns {
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        .triangle {
          display: none;
          position: absolute;
          top: -9px;
          left: 10px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid rgba(255, 150, 0, 0.6);
        }
        .tooltip {
          display: none;
          position: absolute;
          top: -42px;
          left: -5px;
          width: max-content;
          padding: 5px 10px;
          border-radius: 5px 10px;
          border: 1px solid rgba(255, 150, 0, 0.8);
          background: transparent;
          span {
            font-size: 14px;
            font-family: $font-regular;
            color: $btn-clr;
            opacity: 0.8;
          }
        }
        &:hover {
          .triangle {
            display: block;
          }
          .tooltip {
            display: block;
          }
          margin-top: -3px;
          transition: 0.3s;
        }
        .hover-change {
          // opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &-description {
      color: #868688;
      font-family: $font-regular;
      margin: 15px 0;
    }
    &-btn {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .sharedContent{
    &-image-placeholder{
      height: 180px;
    }
    &-image-container{
      height: 180px;
    }
    &-info{
      &-title{
        font-size: 1rem;
        margin-bottom: 10px;
      }
      &-meta{
        &-item{
          font-size: 0.7rem;
        }
      }
      &-btns{
        .activity-btns{
          margin-right: 10px;
        }
      }
      &-description{
        font-size: 0.9rem;
      }
    }
  }
}
</style>